.site-wrapper {
  .site-main {
    margin-inline: 15%;
    margin-block-end: 10%;
    padding-block-start: 60px;

    @media(width < 768px) {
      margin-inline: 20px;
      margin-block-end: 20px;
    }

    section {
      padding-inline: 28px;
      padding-inline: 0;
      padding-block-start: 56px;

      h2 {
        margin-block-start: 0;
      }

      @media(width < 768px) {
        margin-block: 0px;

        h2 {
          font-size: 20px;
        }
      }
    }

    a {
      color: var(--interactive-color);
    }

    ul {
      margin-block: 12px;
      list-style: none;

      li {
          color: var(--text-color);
      }
      li::before {
          content: "\2022";  /* \2022 is the CSS Code/unicode for a bullet */
          color: var(--interactive-color);
          font-weight: bold;
          display: inline-block;
          width: 1em; 
          margin-left: -1em;
        }
  }

    #Ethos {
      padding-inline: 24px;
      margin-block: 32px;
      margin-inline: 24px;
      background-color: var(--background-color-fill);
      border: 1px solid var(--border-color);
      border-radius: 6px;

      strong {
        color: var(--interactive-color);
      }

      @media(width < 768px) {
        margin-inline: 0;
      }
    }

    .technologies_list {
      display: flex;

    }
  }
}


